<template>
  <div :class="{ loading: loading }" class="at-a-glance">
    <UserProfile
      v-if="offeringType === 'course'"
      ref="userprofile"
      :on-update="update"
      :loading="loaded && loading"
      :offering-level="offeringLevel"
      :offering-category="offeringCategory"
    />
    <LinkPanel
      v-if="
        offeringType !== 'course' && relatedCourses && relatedCourses.length
      "
      :offering-level="offeringLevel"
      :courses="relatedCourses"
    />
    <Loader
      v-if="loaded && loading"
      aria-label="Loading"
      class="at-a-glance__loader"
    />
    <transition-group
      v-if="loaded && !loading && offeringType === 'course'"
      name="fade"
      tag="ul"
      data-test="at-a-glance-items"
      class="at-a-glance__items list-reset"
    >
      <li
        v-for="(req, index) in featuredScores"
        :key="`req-${index}`"
        class="at-a-glance__item"
      >
        <ScoreOverviewPanel
          :title="req.heading"
          :value="formatScore(req.score)"
          :label="req.subheading"
          :highlight="index === 0"
          :atar="req.isAtar"
          :link="scoreOverviewLink"
        />
      </li>
      <li v-if="prerequisites" key="prerequisite" class="at-a-glance__item">
        <CourseOverviewPanel
          :title="prerequisites.title"
          :content="prerequisites.content"
          :prerequisite="true"
        />
      </li>
      <li
        v-for="(fee, index) in fees"
        :key="`fees-${index}`"
        class="at-a-glance__item"
      >
        <CourseOverviewPanel
          :title="fee.title"
          :href="courseUrl + feesUrl"
          :icon="fee.icon"
          :content="fee.content"
          :ksp="true"
          :moreinfo="fee.moreinfo"
          :currency="fee.currency"
        />
      </li>
      <li v-if="ksp" key="ksp" class="at-a-glance__item">
        <CourseOverviewPanel
          :title="ksp.title"
          :icon="ksp.icon"
          :ksp="true"
          :prerequisite="ksp.prerequisite ? ksp.prerequisite : undefined"
        />
      </li>
    </transition-group>
    <CtaPanel :data="ctaButtonsArr" padded />
  </div>
</template>

<script>
import { get } from '@/helpers/get';
import { formatDollars } from '@/helpers/client';
import CtaPanel from '~/components/CtaPanel.vue';
import LinkPanel from '~/components/LinkPanel.vue';
import UserProfile from '~/components/UserProfile.vue';
import CourseOverviewPanel from '~/components/CourseOverviewPanel.vue';
import ScoreOverviewPanel from '~/components/ScoreOverviewPanel.vue';
import Loader from '~/components/Loader.vue';

export default {
  components: {
    CtaPanel,
    LinkPanel,
    UserProfile,
    CourseOverviewPanel,
    ScoreOverviewPanel,
    Loader,
  },
  props: {
    offeringType: {
      type: String,
      default: 'course',
    },
    offeringLevel: {
      type: String,
      default: 'undergraduate',
    },
    relatedCourses: {
      type: [Array, Boolean],
      default: false,
    },
    ctaButtonsArr: {
      type: Array,
      default: () => [{}],
    },
    offeringCategory: {
      type: String,
      default: '',
    },
    course: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      loaded: false,
      entryRequirementsUrl: '/entry-requirements',
      feesUrl: '/fees',
    };
  },
  computed: {
    scoreOverviewLink() {
      return this.courseUrl + this.entryRequirementsUrl;
    },
    courseUrl() {
      return `${process.env.basePath}/courses/${this.$route.params.component}/${this.$route.params.slug}`;
    },
    activeYear() {
      const selectedYear = this.$store.getters.getProfile.year;
      const { activeYear } = this.$store.getters.getFeatureFlags;

      return selectedYear || activeYear;
    },
    getResidency() {
      return this.$store.getters.studentType.residency.toLowerCase();
    },
    fees() {
      const residency = this.getResidency;

      const items = [];
      const dataByResidency = get(this.course, [
        'at_a_glance',
        'fee',
        residency,
      ]);

      const feeType = dataByResidency
        ? this.mapFeeType(dataByResidency.type)
        : 'none';

      let selectedFee = '';
      const feeAmountByYear = get(
        dataByResidency,
        ['fee_amount_by_year'],
        false,
      );

      if (feeAmountByYear) {
        selectedFee = feeAmountByYear.find(
          (feeYearObj) =>
            parseInt(feeYearObj.year, 10) === parseInt(this.activeYear, 10),
        );
      }

      switch (feeType) {
        case 'fee':
          if (selectedFee) {
            items.push({
              title: this.formatFee(get(selectedFee, ['fee_amount'], '')),
              content: dataByResidency.title,
              icon: 'dollar',
              currency: 'AUD',
            });
          }
          break;
        case 'csp':
          items.push({
            title: this.$t('page.course.fees.overview.csp.title'),
            content: '',
            icon: 'dollar',
            currency: '',
          });
          break;
        case 'rtp':
          items.push({
            title: this.$t('page.course.fees.overview.rtp.title'),
            content: '',
            icon: 'dollar',
            currency: '',
          });
          break;
        case 'none':
        default:
          break;
      }

      return items;
    },
    ksp() {
      if (this.course.at_a_glance.key_selling_point) {
        return {
          title: this.course.at_a_glance.key_selling_point,
          icon: 'check',
        };
      }
      return false;
    },
    accessMelbourne() {
      if (
        this.offeringType === 'undergraduate' &&
        this.getResidency !== 'international'
      ) {
        return { title: 'Access Melbourne' };
      }
      return false;
    },
    prerequisites() {
      if (this.entryRequirements && this.preRequisitesByProfile) {
        return {
          title: 'Prerequisites',
          content: this.preRequisitesByProfile.contents.specific,
          prerequisite: true,
        };
      }

      return false;
    },
    courseCode() {
      return get(this.course, ['metadata', 'offering_code'], null);
    },
    entryRequirements() {
      return this.$store.getters['offerings/entryRequirements'];
    },
    preRequisitesByProfile() {
      return get(this.entryRequirements, ['byProfile', 'preRequisites'], null);
    },
    featuredScores() {
      return get(this.entryRequirements, ['byProfile', 'featuredScores'], []);
    },
  },
  mounted() {
    // eslint-disable-next-line no-return-assign
    this.$nextTick(() => (this.loaded = true));
  },
  methods: {
    mapFeeType(type) {
      // Due to incompatibles not sending the same fee type as compatibles,
      // This method is added to do the same Composer's mapping on regular offering to incompatibles.
      // But still accounting the values coming from regular offerings.
      // It will be kept until incompatible offerings are not converted to compatibles
      const mapping = {
        'ff-indicative': 'fee',
        'ff-year': 'fee',
        'custom-1': 'fee',
        'custom-2': 'fee',
        'custom-3': 'fee',
        'custom-4': 'fee',
        'csp-total': 'csp',
        none: 'none',
        fee: 'fee',
        csp: 'csp',
        rtp: 'rtp',
      };

      return mapping[type] || 'none';
    },
    formatScore(value) {
      const floatValue = parseFloat(value);
      const toFixedPosition = 2;

      if (!Number.isNaN(floatValue)) {
        return floatValue.toFixed(toFixedPosition).toString();
      }

      if (value === 'Range of criteria used for selection') {
        return 'RC';
      }

      return value;
    },
    formatFee(fee) {
      return fee.length ? formatDollars(fee) : '';
    },
    async update(payload) {
      this.loading = true;
      await this.$store.dispatch('updateProfile', { ...payload });
      await this.$store.dispatch('offerings/setEntryRequirements');
      this.loading = false;
      this.loaded = true;
    },
  },
};
</script>
